const routes =
    [
        {
            path: '/copywriting/index',
            name:'copywritingIndex',
            component:() => import('@/modules/copywriting/pages/index/index.vue'),
            meta:{title:'心理测试首页'},
        },
      {
            path: '/copywriting/article/category/index',
            name:'copywritingArticleCategory',
            component:() => import('@/modules/copywriting/pages/article_category/index.vue'),
            meta:{title:'文案分类'},
        },
      {
            path: '/copywriting/article/item/index',
            name:'copywritingArticleItem',
            component:() => import('@/modules/copywriting/pages/article_item/index.vue'),
            meta:{title:'文案列表'},
        },
      {
            path: '/copywriting/picture/category/index',
            name:'copywritingPictureCategory',
            component:() => import('@/modules/copywriting/pages/picture_category/index.vue'),
            meta:{title:'壁纸分类'},
        },
      {
            path: '/copywriting/picture/item/index',
            name:'copywritingPictureItem',
            component:() => import('@/modules/copywriting/pages/picture_item/index.vue'),
            meta:{title:'壁纸列表'},
        },

    ]
export default routes

