<template>
  <div>
    <div class="box" v-for="(item,index) in itemList" :key="index">
      <div class="box-header">
        <div class="title">{{item.title}}</div>
      </div>
      <div class="box-content">
        <a-row :gutter="16">
          <a-col :span="12" v-for="(itemChildren,indexChildren) in item.children" :key="indexChildren">
            <div class="link"><router-link :to="itemChildren.path">{{itemChildren.title}}</router-link></div>
          </a-col>
        </a-row>
      </div>
    </div>
    <!--box-->
  </div>
</template>

<script setup>
import {
  DesktopOutlined,
  ProjectOutlined, ProfileOutlined, SolutionOutlined,RetweetOutlined,AuditOutlined,
  DatabaseOutlined,
  ContainerOutlined,FileOutlined,SettingOutlined,
  TeamOutlined,UserSwitchOutlined,
  FileMarkdownOutlined,ControlOutlined,
  RollbackOutlined,
} from '@ant-design/icons-vue';
import {reactive, ref} from "vue";
const itemList = [
  {
    title:"文案管理",
    children:[
      {title:"文案分类", path:"/copywriting/article/category/index",},
      {title:"文案列表", path:"/copywriting/article/item/index",},
    ]
  },
  {
    title:"壁纸管理",
    children:[
      {title:"壁纸分类", path:"/copywriting/picture/category/index",},
      {title:"壁纸列表", path:"/copywriting/picture/item/index",},
    ]
  },

]
</script>

<style scoped>
.box{
  margin: 0 5px;
  padding: 10px;
  border-bottom: 1px dashed #e5e3e3;
}
.box-header{}
.box-header .title{
  font-size:15px;
  color: rgba(0,0,0,0.8);
  font-weight: bold;
}
.box-content{
  padding-top: 6px;
}
.box-content .link{
  height: 30px;
  line-height: 30px;
  overflow: hidden;
}
.box-content .link a{
  color: rgba(0,0,0,0.8);
  font-size:14px;
}
.box-content .link a:hover{
  color: #2e83f3;
}
</style>