const routes =
    [
        {
            path: '/workorder/index',
            name:'workorderIndex',
            component:() => import('@/modules/workorder/pages/index/index.vue'),
            meta:{title:'工单工作台'},
        },
      {
            path: '/workorder/help/item/index',
            name:'workorderHelpItemIndex',
            component:() => import('@/modules/workorder/pages/help_item/index.vue'),
            meta:{title:'帮助中心'},
        },


    ]
export default routes

