//常量定义
export const UserInfo = 'UserInfo' //用户信息
export const AccessToken = 'AccessToken' //token常量
export const  DictAllList = 'DictAllList' //字典常量
export const  CheckedCompanyAppId = 'CheckedCompanyAppId' //当前切换选中的企业
export const  CheckedCompany = 'CheckedCompany' //当前切换选中的企业

export const  RequestAccessSecret = 'RequestAccessSecret33333434dd'// 请求密钥




