<template>
  <div style="text-align: center">
    Ant Design ©2023 Created by Qiu Yun
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>