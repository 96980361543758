<template>
  <router-link to="/">
    <div class="logo logo-title" >平台管理系统</div>
  </router-link>
</template>
<script setup>
</script>

<style scoped>
.logo{
  height: 64px;
  line-height: 65px;
  /*margin: 10px;*/
  background: #002140;
}
.logo-title{
  text-align: center;
  color: #ffffff;
  font-size: 15px;
}
</style>