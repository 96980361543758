const routes =
    [
        {
            path: '/choosecourse/index',
            name:'choosecourseIndex',
            component:() => import('@/modules/choosecourse/pages/index/index.vue'),
            meta:{title:'老丁选课首页'},
        },
        {
            path: '/choosecourse/school/school/index',
            name:'choosecourseSchoolIndex',
            component:() => import('@/modules/choosecourse/pages/school/school/index.vue'),
            meta:{title:'机构列表'},
        },
        {
            path: '/choosecourse/school/course/index',
            name:'choosecourseSchoolCourseIndex',
            component:() => import('@/modules/choosecourse/pages/school/course/index.vue'),
            meta:{title:'课程列表'},
        },
        {
            path: '/choosecourse/school/course/category/index',
            name:'choosecourseSchoolCourseCategoryIndex',
            component:() => import('@/modules/choosecourse/pages/school/CourseCategory/index.vue'),
            meta:{title:'课程分类'},
        },
        {
            path: '/choosecourse/school/coupon/index',
            name:'choosecourseSchoolCouponIndex',
            component:() => import('@/modules/choosecourse/pages/school/coupon/index.vue'),
            meta:{title:'代金券列表'},
        },
        {
            path: '/choosecourse/agent/index',
            name:'choosecourseAgentIndex',
            component:() => import('@/modules/choosecourse/pages/agent/agent/index.vue'),
            meta:{title:'代理商列表'},
        },
        {
            path: '/choosecourse/integral/shop/goods/index',
            name:'choosecourseIntegralShopGooodsIndex',
            component:() => import('@/modules/choosecourse/pages/IntegralShop/goods/index.vue'),
            meta:{title:'积分商品'},
        },
        {
            path: '/choosecourse/integral/shop/order/index',
            name:'choosecourseIntegralShopOrderIndex',
            component:() => import('@/modules/choosecourse/pages/IntegralShop/order/index.vue'),
            meta:{title:'积分订单'},
        },
        {
            path: '/choosecourse/role/parent/member/index',
            name:'choosecourseRoleParentMemberIndex',
            component:() => import('@/modules/choosecourse/pages/role/ParentMember/index.vue'),
            meta:{title:'家长会员'},
        },
        {
            path: '/choosecourse/role/school/member/index',
            name:'choosecourseRoleSchoolMemberIndex',
            component:() => import('@/modules/choosecourse/pages/role/SchoolMember/index.vue'),
            meta:{title:'机构会员'},
        },
        {
            path: '/choosecourse/role/platform/member/index',
            name:'choosecourseRolePlatformMemberIndex',
            component:() => import('@/modules/choosecourse/pages/role/PlatformMember/index.vue'),
            meta:{title:'管理员列表'},
        },
        {
            path: '/choosecourse/role/group/index',
            name:'choosecourseRoleRoleGroupIndex',
            component:() => import('@/modules/choosecourse/pages/role/RoleGroup/index.vue'),
            meta:{title:'角色权限'},
        },
        {
            path: '/choosecourse/capital/coupon/order/index',
            name:'choosecourseCapitalCouponOrderIndex',
            component:() => import('@/modules/choosecourse/pages/capital/CouponOrder/index.vue'),
            meta:{title:'订单列表'},
        },
        {
            path: '/choosecourse/capital/order/after/index',
            name:'choosecourseCapitalOrderAfterIndex',
            component:() => import('@/modules/choosecourse/pages/capital/OrderAfter/index.vue'),
            meta:{title:'售后订单'},
        },
        {
            path: '/choosecourse/capital/settlement/index',
            name:'choosecourseCapitalSettlementIndex',
            component:() => import('@/modules/choosecourse/pages/capital/settlement/index.vue'),
            meta:{title:'结算列表'},
        },
        {
            path: '/choosecourse/system/city/index',
            name:'choosecourseSystemCityIndex',
            component:() => import('@/modules/choosecourse/pages/system/city/index.vue'),
            meta:{title:'城市管理'},
        },
        {
            path: '/choosecourse/system/agent/level/index',
            name:'choosecourseSystemAgentLevelIndex',
            component:() => import('@/modules/choosecourse/pages/system/AgentLevel/index.vue'),
            meta:{title:'代理分佣设置'},
        },
        {
            path: '/choosecourse/news/article/category/index',
            name:'choosecourseNewsArticleCategoryIndex',
            component:() => import('@/modules/choosecourse/pages/news/ArticleCategory/index.vue'),
            meta:{title:'文档分类'},
        },
        {
            path: '/choosecourse/news/article/item/index',
            name:'choosecourseNewsArticleItemIndex',
            component:() => import('@/modules/choosecourse/pages/news/ArticleItem/index.vue'),
            meta:{title:'文档列表'},
        },
        {
            path: '/choosecourse/news/help/item/index',
            name:'choosecourseNewsHelpItemIndex',
            component:() => import('@/modules/choosecourse/pages/news/HelpItem/index.vue'),
            meta:{title:'帮助文档'},
        },

    ]
export default routes

