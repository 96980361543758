import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css';
import './global.css'
import router from '@/config/index'
import CommonDivider from "@/components/components_common/other/CommonDivider.vue";
import CommonPagination from "@/components/components_common/common/CommonPagination.vue";


const app = createApp(App)
app.component('common-divider',CommonDivider)
app.component('common-pagination',CommonPagination)
app.use(router)
// app.config.productionTip = false
app.use(Antd)
app.mount('#app')
