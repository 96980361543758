<template>
  <div>
    <a-menu mode="horizontal">
      <a-menu-item key="wenan">
        <router-link to="/copywriting/index">文案</router-link>
      </a-menu-item>
      <a-menu-item key="psych">
        <router-link to="/psych/index">心理</router-link>
      </a-menu-item>
      <a-menu-item key="workorder">
        <router-link to="/workorder/index">工单</router-link>
      </a-menu-item>
      <a-menu-item key="choosecourse">
        <router-link to="/choosecourse/index">老丁选课</router-link>
      </a-menu-item>
      <a-menu-item key="gamework">
        <router-link to="/gamework/index">游戏</router-link>
      </a-menu-item>
    </a-menu>
  </div>
</template>
<script setup>
</script>

<style scoped>

</style>