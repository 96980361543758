const routes =
    [
        {
            path: '/psych/index',
            name:'psychIndex',
            component:() => import('@/modules/psych/pages/index/index.vue'),
            meta:{title:'心理测试首页'},
        },
      {
            path: '/psych/paper/category/index',
            name:'psychPaperCategory',
            component:() => import('@/modules/psych/pages/paper_category/index.vue'),
            meta:{title:'题库分类'},
        },
      {
            path: '/psych/paper/item/index',
            name:'psychPaperItem',
            component:() => import('@/modules/psych/pages/paper_item/index.vue'),
            meta:{title:'题库列表'},
        },
      {
            path: '/psych/paper/score/index/:item_no',
            name:'psychPaperScoreIndex',
            component:() => import('@/modules/psych/pages/paper_score/index.vue'),
            meta:{title:'题库分值管理'},
        },
      {
            path: '/psych/question/options/index/:item_no',
            name:'psychPaperOptionsIndex',
            component:() => import('@/modules/psych/pages/question_item/index.vue'),
            meta:{title:'题库题干信息管理'},
        },
      {
            path: '/psych/question/options/detail/:item_no',
            name:'psychPaperOptionsDetail',
            component:() => import('@/modules/psych/pages/question_item/detail.vue'),
            meta:{title:'题库题干选项信息管理'},
        },
      {
            path: '/psych/paper/result/index',
            name:'psychResultItem',
            component:() => import('@/modules/psych/pages/result_item/index.vue'),
            meta:{title:'测试结果'},
        },

    ]
export default routes

