
import BlankLayout from "@/layouts/BlankLayout.vue";
import PlatformLayout from "@/components/components_platform/PlatformLayout.vue";

import PsychLayout from "@/modules/psych/layouts/PsychLayout.vue";
import psychRoute from "@/modules/psych/routes/psychRoute";

import WorkorderLayout from "@/modules/workorder/layouts/WorkorderLayout.vue";
import workorderRoutes from "@/modules/workorder/routes/workorderRoute";

import CopyWritingLayout from "@/modules/copywriting/layouts/CopyWritingLayout.vue";
import copyWritingRoute from "@/modules/copywriting/routes/copyWritingRoute";

import ChooseCourseLayout from "@/modules/choosecourse/layouts/ChooseCourseLayout.vue";
import choosecourseRoute from "@/modules/choosecourse/routes/choosecourseRoute";

import GameWorkLayout from "@/modules/gamework/layouts/GameWorkLayout.vue";
import GameWorkRoute from "@/modules/gamework/routes/GameWorkRoute";

const routes = [
  {
    path:'/',
    component:PlatformLayout,
    // component:()=> import('@/modules/workorder/pages/index/index.vue'),
    children:[
      {
        path: '/',
        component:() => import('@/pages/index/index.vue'),
        meta:{title:'工作台'},
      },
    ]
  },
  {
    path:'/psych',
    component:PsychLayout,
    // component:()=> import('@/modules/workorder/pages/index/index.vue'),
    children:psychRoute
  },
  {
    path:'/gamework',
    component:CopyWritingLayout,
    // component:()=> import('@/modules/workorder/pages/index/index.vue'),
    children:copyWritingRoute
  },
  {
    path:'/gamework',
    component:GameWorkLayout,
    // component:()=> import('@/modules/workorder/pages/index/index.vue'),
    children:GameWorkRoute
  },
  {
    path:'/workorder',
    component:WorkorderLayout,
    // component:()=> import('@/modules/workorder/pages/index/index.vue'),
    children:workorderRoutes
  },
  {
    path:'/choosecourse',
    component:ChooseCourseLayout,
    // component:()=> import('@/modules/workorder/pages/index/index.vue'),
    children:choosecourseRoute
  },
  {
    path:'/login',
    component:BlankLayout,
    children:[
      {
        path: '/login/index',
        name:'login',
        component:() => import('@/pages/login/index.vue'),
      },
      {
        path: '/wechat/callback',
        name:'wechatCallback',
        component:() => import('@/pages/login/WechatCallback.vue'),
      },

    ]
  },
]
export default routes