<template>
<div>
  <a-pagination
      v-model:current="props.currentPageIndex"
      v-model:pageSize="props.parentPageSize"
      v-model:total="props.parentTotalCount"
      show-size-changer
      @change="handleEventChange"
  >
    <template #buildOptionText="item">
      <span>{{ item.value }}条/页</span>
    </template>
  </a-pagination>
</div>
</template>

<script setup>
import { defineComponent, ref, defineEmits } from 'vue';

const emits = defineEmits(['listenParentPagination']);
const props = defineProps({
  currentPageIndex: {
    type:Number,
    default:()=>{
      return 1;
    }
  },
  parentTotalCount: {
    type:Number,
    default:()=>{
      return 0;
    }
  },
  parentPageSize: {
    type:Number,
    default:()=>{
      return 10;
    }
  },
})
// const handleEventShowSizeChange = (current, pageSize) => {
//   let params = {
//     page_index:current,
//     page_size:pageSize,
//   }
//   console.log('切换页面-1',params)
//   emits('listenParentPagination', params) //向父级组件通信
// };
//listenPaginationParentEvent
const handleEventChange = (current, pageSize) => {
  let params = {
    page_index:current,
    page_size:pageSize,
  }
  console.log('切换页面-2',params)
  emits('listenParentPagination', params) //向父级组件通信
}

</script>

<style scoped>

</style>