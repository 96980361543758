<template>
  <div class="box">
    <a-row>
      <a-col :span="18">
        <div class="box-left">
          <PlatformMenu ></PlatformMenu>
        </div>
      </a-col>
      <a-col :span="6">
        <div style="text-align: right;">
          <CustomerServiceOutlined class="mu-mr-20" :style="{fontSize: '20px',cursor:'pointer'}" />
          <a-badge :count="5" class="mu-mr-20">
            <MessageOutlined :style="{fontSize: '20px',cursor:'pointer'}" />
          </a-badge>

          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              <a-avatar :src="userInfo.avatar" />
              {{userInfo.user_name}}
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <router-link to="/company/detail">
                    企业信息
                  </router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link to="/user/index">
                    个人资料
                  </router-link>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="handleEventLogout">退出登录</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import {DownOutlined,UserOutlined,ExclamationCircleOutlined,
  MessageOutlined,BellOutlined,CustomerServiceOutlined
} from "@ant-design/icons-vue"
import {ref,reactive,createVNode} from "vue"
import {AccessToken, UserInfo} from "@/utils/constant";
import {message, Modal} from "ant-design-vue";
import {useRouter} from "vue-router";
import CommonAlert from "@/components/components_business/header/CommonAlert.vue";
import PlatformMenu from "@/components/components_platform/PlatformMenu.vue";
const router = useRouter()

let userInfo = reactive(JSON.parse(localStorage.getItem(UserInfo)))

const handleEventLogout = () => {
  console.log('测试退出')
  Modal.confirm({
    title: '确定要退出系统吗?',
    icon: createVNode(ExclamationCircleOutlined),
    content: "确认退出请点击【确定】，否则请点击【取消】",
    okText: '确定',
    cancelText: '取消',
    onOk() {
      message.success('退出登录')
      //清空缓存
      localStorage.removeItem(UserInfo);
      localStorage.removeItem(AccessToken);
      console.log('OK');
      router.push({name:'login'})
    },
    onCancel() {
      console.log('Cancel');
    },
    class: 'test',
  });
}
</script>

<style scoped>
.box{
  margin: 0 20px;
}
.box-left{
  height: 60px;
  line-height: 60px;
}
.mu-alert{
  margin-top: 10px;
}
</style>