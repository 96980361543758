const routes =
    [
        {
            path: '/gamework/index',
            name:'gameworkIndex',
            component:() => import('@/modules/gamework/pages/index/index.vue'),
            meta:{title:'游戏首页'},
        },
      {
            path: '/gamework/customer/item/index',
            name:'gameworkCustomerItem',
            component:() => import('@/modules/gamework/pages/customer_item/index.vue'),
            meta:{title:'客户管理'},
        },
      {
            path: '/gamework/customer/mac/index',
            name:'gameworkCustomerMac',
            component:() => import('@/modules/gamework/pages/customer_mac/index.vue'),
            meta:{title:'Mac管理'},
        },
      {
            path: '/gamework/customer/token/index',
            name:'gameworkCustomerToken',
            component:() => import('@/modules/gamework/pages/customer_token/index.vue'),
            meta:{title:'密钥管理'},
        },
      {
            path: '/gamework/game/type/index',
            name:'gameworkGameType',
            component:() => import('@/modules/gamework/pages/game_type/index.vue'),
            meta:{title:'游戏类型'},
        },
      

    ]
export default routes

