import {createRouter, createWebHistory} from 'vue-router'
import routes from "@/config/routes";
import {AccessToken} from "@/utils/constant";
const router = createRouter({
  history: createWebHistory(),
  routes:routes,
})
router.beforeEach((to, from) => {
  //to: 即将要进入的目标 用一种标准化的方式
  //from: 当前导航正要离开的路由 用一种标准化的方式
  //return false: 取消当前的导航。
  // 返回 false 以取消导航
  // console.log('路由导航to',to)
  // return false
  const token = localStorage.getItem(AccessToken)
  const noAccess = ['login','wechatCallback',] //不需要校验权限的路由name
  if (
    // 检查用户是否已登录// ❗️ 避免无限重定向
    !token && !noAccess.includes(to.name)
  ) {
    // 将用户重定向到登录页面
    return { name: 'login' }
  }

// canUserAccess() 返回 `true` 或 `false`
//   const canAccess = await canUserAccess(to)
//   if (!canAccess) return '/login'

})

export default router
