<template>

  <div :style="{ padding: props.height+'px' }"></div>

</template>
<script setup>
const props = defineProps({
  height: {
    type:Number,
    default:10
  }
})
</script>

<style scoped>

</style>